
export const getTechniqueMapped = (sanityTechnique) => {
  const seo = setSeo(sanityTechnique);
  return {
    advantages: sanityTechnique.advantages,
    heroPrice: sanityTechnique.priceHero,
    simplybookId: sanityTechnique.simplybookId,
    faq: sanityTechnique.faq,
    background: sanityTechnique.hero.background,
    background_alt: sanityTechnique.hero.background_alt,
    shortTitle: sanityTechnique.card.title,
    subtitle: sanityTechnique.hero.subtitle,
    id: sanityTechnique._id,
    title: sanityTechnique.hero.title,
    seo,
    slug: sanityTechnique.slug.current,
    photo: sanityTechnique.card.photo,
    photo_alt: sanityTechnique.card.photo_alt,
    content: sanityTechnique.card._rawContent,
  };
  function setSeo(data){
    if(data.seo){
      return {
        title: sanityTechnique.seo.title,
        description: sanityTechnique.seo.desc,
        canonicals: sanityTechnique.seo.canonical,
        robots: sanityTechnique.seo.seo_robots,
      }
    }else{
      return {
        title: sanityTechnique.hero.title,
        description: sanityTechnique.hero.subtitle,
        canonicals: "",
        robots: "index, follow",
      }
    }
  }
};

