import "./reviews.scss";

import * as React from "react";

import ReviewItems from "./ReviewItems/ReviewItems";

const Reviews: React.FC = () => {
  return (
    <section className={"o-reviews"}>
      <h2 className={"a-review-title container"}>
        Cómo valoran nuestro servicio de fisioterapia
      </h2>
      <ReviewItems />
    </section>
  );
};

export default Reviews;
