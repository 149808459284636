
export const getOtherServiceMapped = (sanityOtherService) => {
  const seo = setSeo(sanityOtherService);
  const list = setList(sanityOtherService)
  return {
    background: sanityOtherService.hero.background,
    background_alt: sanityOtherService.hero.background_alt,
    advantages: sanityOtherService.advantages,
    faq: sanityOtherService.faq,
    color: sanityOtherService.color,
    list,
    shortTitle: sanityOtherService.card.title,
    subtitle: sanityOtherService.hero.subtitle,
    id: sanityOtherService._id,
    title: sanityOtherService.hero.title,
    seo,
    slug: sanityOtherService.slug.current,
    photo: sanityOtherService.card.photo,
    photo_alt: sanityOtherService.card.photo_alt,
    content: sanityOtherService.card._rawContent
  };
};

function setList(data){
  if(data.enumList){
    return {
      list: data.enumList
    }
  } else {
    return { list: null };
  }
}

function setSeo(data){
  if(data.seo){
    return {
      title: data.seo.title,
      description: data.seo.desc,
      canonicals: data.seo.canonical,
      robots: data.seo.seo_robots,
    }
  }else{
    return {
      title: data.hero.title,
      description: data.hero.subtitle,
      canonicals: "",
      robots: "index, follow",
    }
  }
}
