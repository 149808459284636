import "./we-techniques.scss";

import { Link } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import * as React from "react";
import { createRef, PropsWithChildren, useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import ScrollContainer from "react-indiana-drag-scroll";

import { OtherService } from "../../../contexts/othersServices/domain/OtherService";
import { Technique } from "../../../contexts/techniques/domain/Technique";

interface PropsWeTechniques extends PropsWithChildren<any> {
  services: Technique[] | OtherService[];
  title: string;
}

const WeTechniques: React.FC<PropsWeTechniques> = (
  props: PropsWeTechniques
) => {
  const { services, title } = props;
  const sliderTechniques = createRef(null);
  let withCard;

  useLayoutEffect(() => {
    function resize() {
      withCard =
        document.getElementsByClassName("m-technique-card")[0].clientWidth + 35;
    }
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const move = (moveLong: number) => {
    const rdSlider = ReactDOM.findDOMNode(sliderTechniques.current);
    rdSlider.scrollTo(moveLong * withCard, 0);
  };

  const techniques_render = [];
  const dots_render = [];
  services.forEach((service, index) => {
    if (!service.photo) {
      return;
    }
    dots_render.push(
      <span
        onClick={() => {
          move(index);
        }}
        key={`dot-${service.id}`}
      >
        O
      </span>
    );
    let slug = `/${service.slug}/`;
    if (!service.slug || service.slug === "/") {
      slug = "/";
    }
    techniques_render.push(
      <Link
        to={slug}
        key={service.id}
        className={"technique-link"}
        id={service.id}
      >
        <div className={"m-technique-card"}>
          <SanityImage
            {...service.photo}
            alt={service?.photo_alt}
            className={"a-image a-image--rounded"}
          />
          <h3>{service.shortTitle}</h3>
        </div>
      </Link>
    );
  });
  techniques_render.push(
    <div className={"is-fake"} key={"empty-technique"}></div>
  );

  return (
    <section className={"o-we-techniques"}>
      <div className={"m-description"}>
        <h2>{title}</h2>
        {props.children}
      </div>
      <ScrollContainer className="scroll-container" ref={sliderTechniques}>
        <div className={"scroll-container__content"}>{techniques_render}</div>
      </ScrollContainer>
      <div className={"m-navigation-slider"}>{dots_render}</div>
    </section>
  );
};

export default WeTechniques;
