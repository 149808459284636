import "./review-items.scss";

import { graphql, Link, useStaticQuery } from "gatsby";
// import Slider from "infinite-react-carousel";
import * as React from "react";
import { createRef, useLayoutEffect, useState } from "react";
import ReactDOM from "react-dom";
import ScrollContainer from "react-indiana-drag-scroll";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import icon from "../../../assets/vectors/icon.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import star from "../../../assets/vectors/star.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const ReviewItems: React.FC = () => {
  const [readMore, setReadMore] = useState(false);
  const sliderReviews = createRef(null);
  let withCard;

  useLayoutEffect(() => {
    function resize() {
      withCard = document.getElementsByClassName("m-review-cards__item")[0]
        .clientWidth;
    }
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const move = (moveLong: number) => {
    const rdSlider = ReactDOM.findDOMNode(sliderReviews.current);
    rdSlider.scrollTo(moveLong * withCard, 0);
  };

  const reviews = useStaticQuery(
    graphql`
      query Reviews {
        allGooglePlacesReview {
          nodes {
            text
            rating
            author_name
            id
          }
        }
      }
    `
  );

  const reviews_render = [];
  const dots_render = [];
  reviews.allGooglePlacesReview.nodes.map((review, index) => {
    dots_render.push(
      <span
        onClick={() => {
          move(index);
        }}
        key={review.id}
      >
        {" "}
        O{" "}
      </span>
    );
    reviews_render.push(
      <div
        className={"m-review-cards__item m-review-cards__item--full"}
        key={review.id}
        id={review.id}
        onClick={() => setReadMore(!readMore)}
      >
        <img src={icon} className={"a-image"} />
        <div className={"is-inline-block"}>
          <p className={"a-name"}>{review.author_name}</p>
          {stars(review.rating)}
        </div>
        <div>
          <p className={`a-review ${readMore ? "a-review--read-more" : ""} `}>
            {review.text}
          </p>
        </div>
      </div>
    );
  });
  reviews_render.push(
    <div
      className={"m-review-cards__item m-review-cards__item--full"}
      key={"empty-review"}
    ></div>
  );

  function stars(stars: number) {
    const img = [];
    const maxStart: number = 5;
    for (let i = 0; i < stars; i++) {
      img.push(<img src={star} key={i} />);
    }
    if (stars < maxStart) {
      const toCompleted = maxStart - stars;
      for (let i = 0; i < toCompleted; i++) {
        img.push(<img src={star} key={i} className={"is-translucent"} />);
      }
    }
    return img;
  }
  return (
    <>
      <ScrollContainer className="scroll-container" ref={sliderReviews}>
        <div className={"scroll-container__content"}>{reviews_render}</div>
      </ScrollContainer>
      <div className={"m-navigation-slider"}> {dots_render} </div>
    </>
    // <Slider
    //   dots
    //   slidesToShow={slidesToShow}
    //   arrows={false}
    //   ref={sliderRef}
    //   className={"slider"}
    //   onResize={(e) => {
    //     resize(e);
    //   }}
    // >

    // </Slider>
  );
};

export default ReviewItems;
